.header-wrapper {
  position: sticky;
  top: 0;
  height: 0;

  z-index: theme("zIndex.50");
}

.header {
  /*
    We want to have a gap of spacing.sm, but by using half that value as padding,
    we get a bigger hit target for click/touch.
  */
  --header-trigger-pad-x: calc(theme("spacing.sm") / 2);

  /*
    This is the gap between logo, the main navigation links, and the right-side buttons.
    To get a gap of "spacing.md-lg", we need to subtract the trigger side padding (see above comment).
  */
  --header-middle-group-gap: calc(theme("spacing.md-lg") - var(--header-trigger-pad-x));
  --header-transition-duration: .25s;

  --header-px: theme("spacing.xs");
  --header-py: theme("spacing.2xs");

  /* Offsets the logo margin, so it gets spacing.md distance to left edge */
  --header-logo-ml: calc(theme("spacing.md") - var(--header-px));

  position: absolute;
  overflow: visible;
  top: var(--header-offset, theme("spacing.sm"));
  left: 50%;

  color: theme("colors.satellite.200");

  display: grid;

  max-width: calc(100vw - (2 * theme("spacing.xs-sm")));

  transform: translateX(-50%);
  color: theme("colors.satellite.200");
}

/*
  we use animation with fill mode because we have to switch display to "none" at the end
  otherwise the user would be able to click an invisible menu by accident
*/
.header[data-hidden="true"] {
  animation: hide var(--header-transition-duration) ease;
  animation-fill-mode: forwards;
}

.header[data-hidden="false"] {
  animation: show var(--header-transition-duration) ease;
}

/* ----- Nav: main inner container ----- */
.nav {
  display: flex;
  flex-direction: column;

  padding: var(--header-py) var(--header-px);

  background: rgba(255,255, 255, 0.5);
  border-radius: 1.65em;
  box-shadow: 0px theme("spacing.2xs") theme("spacing.md") 0px rgba(0, 0, 0, 0.1);
  border: 1px solid theme("colors.shades.black.200");
  backdrop-filter: blur(50px);

  transition:
    padding,
    background,
    var(--header-transition-duration) ease;
}

.header[data-state="open"] .nav {
  padding-bottom: theme("spacing.md");
}

.header:hover .nav,
.header[data-state="open"] .nav {
  background: theme("colors.others.white");
}

/* ----- List & items (the content visible without expanding) ----- */
.list {
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.logo-item {
  display: flex;

  margin-left: var(--header-logo-ml);

  width: clamp(5em, 4.8em + 1.25vw, 6.6em);
  margin-right: var(--header-middle-group-gap);
}

.link-group-item {
  display: flex;
}

.link-group-trigger {
  padding: 0 var(--header-trigger-pad-x);

  font-size: theme("fontSize.default-sm");
  font-weight: 700;
}

.link-group-trigger > div {
  display: flex;
  place-items: center;
  gap: theme("spacing.3xs");

  /* Offset the border width at bottom */
  padding: calc(theme("spacing.sm") / 2 - 2px) 0;

  /* Set a transparent border, so we don't get height changes when adding border later */
  border-bottom: 2px solid transparent;
  line-height: theme("lineHeight.tight")
}

.link-group-trigger[data-state="open"] > div {
  border-color: currentColor;
}

.link-group-trigger[data-state="open"] svg {
  border-color: currentColor;

  transform: rotate(180deg);
}

.login-item {
  display: flex;
}

/* Custom button */
.login-item {
  margin-left: calc(var(--header-middle-group-gap) - theme("spacing.2xs"));
}

.login-link {
  padding: theme("spacing.2xs") 1.1em;

  font-size: theme("fontSize.strong-sm");
  font-weight: 700;

  display: flex;
  gap: theme("spacing.3xs");
  place-items: center;

  border-color: theme("colors.satellite.200");
  border-radius: theme("borderRadius.full");
  border-width: 1px;

  color: theme("colors.satellite.200");
}

.markets-item {
  margin-left: theme("spacing.2xs");
}

/* Custom button only for nav for now */
.markets-trigger {
  display: flex;
  gap: theme("spacing.3xs");
  place-items: center;

  padding: theme("spacing.2xs");

  background: theme("colors.shades.black.100");
  border-radius: theme("borderRadius.full");
}

.markets-trigger:hover,
.markets-trigger:focus {
  background: theme("colors.shades.black.200");
}

.markets-trigger:active {
  background: theme("colors.shades.black.300");
}

.markets-trigger img {
  width: calc(theme("fontSize.strong-lg") -  0.05em);

  border-radius: theme("borderRadius.full");
  overflow: hidden;
}

.markets-trigger svg {
  font-size: theme("fontSize.default-sm");
}

.markets-trigger:hover svg,
.markets-trigger:focus svg,
.markets-trigger:active svg {
  transform: rotate(180deg);
}

/* ----- Viewport (the main container when expanding on hover/focus) ----- */
.viewport {
  /*
    Radix computes these values based on content before opening and showing the item content.

    note: this also means that if you resize the screen after opening,
    it will not get reflected properly.
  */
  width: var(--radix-navigation-menu-viewport-width);
  height: var(--radix-navigation-menu-viewport-height);

  overflow: hidden;
  transition: width, height, var(--header-transition-duration) ease;
}

/* ----- Navigation links ----- */
.link-group-content {
  display: flex;
  flex-direction: column;

  padding-top: theme("spacing.md");
}

.nav-link {
  display: flex;
  place-items: center;
  gap: theme("spacing.xs");

  padding: theme("spacing.xs");
  padding-right: theme("spacing.md");
  border-radius: theme("borderRadius.full");
}

.nav-link:hover,
.nav-link:focus {
  background: theme("colors.sky.100");
}

.nav-link:active {
  background: theme("colors.sky.200");
}

.nav-link svg {
  flex-shrink: 0;

  /* 24px icon at 1480, with 8px margin */
  width: 1.2em;
  height: 1.2em;
  margin: calc(theme("spacing.2xs") - 0.1em);

  transition: transform var(--header-transition-duration) ease;
  transform-origin: center;
}

/* Icon zoom effect on hover */
.nav-link:hover svg {
  transform: scale(1.25);
}

.nav-link-title {
  font-size: theme("fontSize.default-md");
  font-weight: 700;
}

.nav-link-text {
  font-size: theme("fontSize.default-sm");
  line-height: theme("lineHeight.tight");
}

/* ----- Markets ----- */
.markets-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;

  max-width: 100%;
}

.markets-text {
  grid-column: 1/-1;

  font-size: theme("fontSize.default-md");
  margin: theme("spacing.md") theme("spacing.sm");
  margin-left: var(--header-logo-ml);
  line-height: theme("lineHeight.tight");
}

.market-link {
  display: flex;
  place-items: center flex-start;
  gap: theme("spacing.xs");

  padding: theme("spacing.sm");
  text-align: left;
  line-height: theme("lineHeight.tight");
  border-radius: theme("borderRadius.full");
}

.market-link:hover,
.market-link:focus {
  background: theme("colors.sky.100");
}

.market-link:active {
  background: theme("colors.sky.200");
}

.market-link img {
  width: 1.2em;
  height: 1.2em;

  border-radius: theme("borderRadius.full");
  overflow: hidden;
}

/* ----- Animations ----- */
@keyframes show {
  0% {
    opacity: 0;
    display: initial;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  99% {
    display: initial;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
