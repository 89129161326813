.header-wrapper {
  position: sticky;
  top: 0;
  height: 0;
  width: 100%;

  z-index: 200;
}

.header {
  --header-transition-duration: 0.25s;

  /*
    All distances and paddings need to be defined here,
    because we want the viewport to "break out" of the padding and go full width.
    The values are then also used to align the inner content of the viewport
    with the top "bar" items.
  */
  --header-outer-distance: theme("spacing.2xs");

  --header-inner-py: theme("spacing.2xs");
  --header-inner-pl: calc(theme("spacing.2xs") + theme("spacing.2xs"));
  --header-inner-pr: theme("spacing.2xs");

  --header-viewport-pl: calc(-1 * (var(--header-outer-distance) + var(--header-inner-pl)));
  --header-viewport-pr: calc(-1 * (var(--header-outer-distance) + var(--header-inner-pr)));

  --header-content-py: calc(var(--header-outer-distance) + var(--header-inner-py));
  --header-content-px: calc(var(--header-outer-distance) + var(--header-inner-pl));

  position: absolute;
  top: var(--header-outer-distance);
  right: var(--header-outer-distance);
  left: var(--header-outer-distance);

  display: flex;
  flex-direction: column;

  transition:
    border-radius,
    border-color,
    var(--header-transition-duration) ease;

  box-shadow: 0px theme("spacing.2xs") theme("spacing.md") 0px
    theme("colors.shades.black.200");
  border: 1px solid theme("colors.shades.black.200");
  border-radius: 2rem;
  overflow: hidden;
  backdrop-filter: blur(50px);
  background: theme("colors.shades.white.100");
}

.header[data-state="open"] {
  position: fixed;
  bottom: 0;
  backdrop-filter: none;
  border-radius: 0;
  border-color: transparent;
  box-shadow: none;
  overflow: visible;
}

/*
  we use animation with fill mode because we have to switch display to "none" at the end
  otherwise the user would be able to click an invisible menu by accident
*/
.header[data-hidden="true"] {
  animation: hide var(--header-transition-duration) ease;
  animation-fill-mode: forwards;
}

.header[data-hidden="false"] {
  animation: show var(--header-transition-duration) ease;
}

.header[data-has-banner="true"] {
  margin-top: theme("spacing.2xs");
}

.backdrop {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background: theme("colors.others.white");
  opacity: 0;
  pointer-events: none;

  transition:
    opacity,
    transform,
    var(--header-transition-duration) ease;
}

.header[data-state="open"] .backdrop {
  transform: scale(1.2);
  opacity: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding:
    var(--header-inner-py)
    var(--header-inner-pr)
    var(--header-inner-py)
    var(--header-inner-pl);


    background: rgba(255,255, 255, 0.5);
    backdrop-filter: blur(50px);
}

.header[data-state="open"] .nav {
  padding-bottom: 0;
}

/* ----- Top content ----- */
.list {
  display: grid;
  grid-template-columns: 1fr repeat(2, max-content);
  grid-template-rows: max-content max-content max-content 1fr max-content;
}

.logo-item {
  width: 5.458em;
  align-self: center;
}

/* Custom markets button only for header */
.markets-trigger {
  display: flex;
  gap: theme("spacing.3xs");
  place-items: center;

  padding: calc(theme("spacing.xs") - 0.111em) calc(theme("spacing.xs") - 0.033em);

  background: theme("colors.shades.black.100");
  border-radius: theme("borderRadius.full");
}

.markets-trigger img {
  width: 1.333em;

  border-radius: 9999px;
  overflow: hidden;
}

.markets-trigger svg {
  font-size: 0.888em;
}

.markets-trigger[data-state="open"] svg {
  transform: rotate(180deg);
}

.markets-trigger:hover {
  background: theme("colors.shades.black.200");
}

.links-item {
  align-self: center;
  height: 100%;

  margin-left: theme("spacing.2xs");
}

/* Custom open/close icon button */
.links-trigger {
  display: grid;
  place-items: center;

  width: 2.666em;
  height: 2.666em;

  background: theme("colors.shades.black.100");
  border-radius: 9999px;
  overflow: hidden;
}

.links-trigger:hover,
.links-trigger:focus,
.links-trigger:active {
  background: theme("colors.shades.black.200");
}

.links-trigger img,
.links-trigger svg {
  width: 1.333em;
}

/* ----- Expanded content main container ----- */
.viewport {
  display: flex;
  flex-direction: column;

  height: 100%;
  margin-top: theme("spacing.sm");
  margin-left: var(--header-viewport-pl);
  margin-right: var(--header-viewport-pr);
  overflow-y: auto;

  /* Animates the viewport in with clip-path */
  animation: viewportIn var(--header-transition-duration) ease;
}

/* ----- Links content ------ */
.links-content {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.links-sub-nav,
.links-sub-nav > div {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.links-sub-list {
  display: grid;
  grid-template-columns: 1fr;
  /* Make sure that the "more" sub item expands the remaining space */
  grid-template-rows: auto auto auto 1fr auto;
  align-items: flex-start;

  height: 100%;
}

.links-sub-item {
  display: grid;
  grid-auto-columns: 1fr;
}

.links-sub-item + .links-sub-item {
  border-top: 1px solid theme("colors.shades.black.200");
}

/*
  Animation trick to fake a full height transition.
  Instead we just instantly pull up the items by a tiny bit, then transition them down again.
*/
.links-sub-item[data-state="open"] {
  animation: slideDown var(--header-transition-duration) ease;
}

.links-sub-item[data-state="open"] ~ .links-sub-item {
  animation: slideDownSibling var(--header-transition-duration) ease;
}

.links-sub-trigger {
  padding: theme("spacing.sm") var(--header-content-px);
  /* Silly micro adjustment to get the icon to line up with close button */
  padding-right: calc(var(--header-content-px) - 0.055em);

  font-size: theme("fontSize.strong-md");
  font-weight: 700;
  line-height: theme("lineHeight.snug");
}

.links-sub-trigger > div {
  display: grid;
  grid-template-columns: 1fr max-content;
  place-items: center flex-start;
}

.links-sub-trigger svg {
  display: block;

  width: 1.777em;
  height: 1.777em;

  padding: 0.444em;

  border-radius: theme("borderRadius.full");
  background: theme("colors.shades.black.100");
  overflow: hidden;
}

.links-sub-trigger[data-state="open"] svg {
  transform: rotate(180deg);
}

.links-sub-content {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.nav-link {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: theme("spacing.2xs");
  place-items: center flex-start;

  padding: theme("spacing.xs");
  padding-right: var(--header-content-pr);

  border-left: 2px solid transparent;
  line-height: theme("lineHeight.tight");
  text-wrap: balance;
}

.nav-link:last-of-type {
  /* Add some more bottom spacing to the container without messing with scrollable area */
  margin-bottom: calc(theme("spacing.sm") * 2);
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
  border-color: theme("colors.satellite.200");
}

.nav-link svg {
  width: 1.5em;
  height: 1.5em;

  margin: theme("spacing.2xs");
  margin-left: theme("spacing.sm");
}

.nav-link-title {
  margin-bottom: theme("spacing.3xs");

  font-size: theme("fontSize.default-md");
  font-weight: 700;
}

/* ----- Markets content ----- */
.markets-sub-nav,
.markets-sub-nav > div {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.markets-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;

  height: 100%;
}

.markets-text {
  font-size: theme("fontSize.default-md");

  padding: calc(theme("spacing.xl") - theme("spacing.sm")) theme("spacing.md")
    theme("spacing.md");
}

.markets-grid {
  display: grid;
  padding: 0 var(--header-content-px) theme("spacing.xl");
}

.markets-content {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.market-link {
  display: flex;
  gap: theme("spacing.sm");
  align-items: center;

  padding: theme("spacing.xs") theme("spacing.md") theme("spacing.xs")
    theme("spacing.sm");
  border-radius: theme("spacing.md");
  overflow: hidden;
  text-align: left;
  font-size: theme("fontSize.default-md");
  line-height: theme("lineHeight.tight");
}

.market-link[data-state="active"],
.market-link:hover,
.market-link:focus {
  background: theme("colors.sky.100");
}

.market-link:active {
  background: theme("colors.sky.200");
}

.market-link img {
  width: 1.333em;
  height: 1.333em;

  border-radius: 9999px;
  overflow: hidden;
}

/* ----- Footer content (shared for both markets and links) -----  */
.footer-item {
  position: sticky;
  bottom: 0;

  display: grid;
  gap: theme("spacing.2xs");

  padding: theme("spacing.2xs") var(--header-content-px);

  background: theme("colors.others.white");
  box-shadow: 0px -4px 14px 0px theme("colors.shades.black.200");
}

.header[data-state="closed"] .footer-item {
  display: none;
}

/* ----- Animations ----- */
@keyframes show {
  0% {
    opacity: 0;
    display: initial;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  99% {
    display: initial;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-1em);
    opacity: 0;
  }
}

@keyframes slideDownSibling {
  from {
    transform: translateY(-1em);
  }
}

@keyframes slideDownSibling {
  from {
    transform: translateY(-1em);
  }
}

/* Circle starting at upper right corner expanding to a full width rectangle */
@keyframes viewportIn {
  from {
    opacity: 0;
    clip-path: circle(0% at 95% 10%);
  }
  to {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
